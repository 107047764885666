import Domain from 'helpers/Domain';
import Plan from 'aa/vue/models/Plan';
import PlanService from 'aa/vue/services/PlanService';
import Product from 'aa/vue/models/Product';
import CheckoutOptions from 'aa/vue/models/CheckoutOptions';

export default {
    namespaced: true,

    state: {
        plans: [],
        planType: Product.TYPE_MONTHLY,
        planTier: null,
        selectedPlan: null,
        addonShowtime: false,
        pickerPlanSelected: false,
        // START TEST PLOCTOPLUS-2947 Annual Plan Emphasis on PAP Adjusted Pricing Display and Hierarchy
        billingCadenceModalOpen: false,
        // END TEST PLOCTOPLUS-2947 Annual Plan Emphasis on PAP Adjusted Pricing Display and Hierarchy
        // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
        activePlan: 0,
        selectedPlanInfo: {},
        // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
    },

    // commit and track state changes
    mutations: {
        // START TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan
        setActivePlan(state, payload) {
            state.activePlan = payload;
        },
        setSelectedPlanInfo(state, payload) {
            state.selectedPlanInfo = payload;
        },
        // END TEST PLOCTOPLUS-2890: A/B TEST: Pre-Select Paramount+ with SHO Plan

        // START TEST PLOCTOPLUS-2947 Annual Plan Emphasis on PAP Adjusted Pricing Display and Hierarchy
        setBillingCadenceModalOpen(state, payload) {
            state.billingCadenceModalOpen = payload;
        },
        // END TEST PLOCTOPLUS-2947 Annual Plan Emphasis on PAP Adjusted Pricing Display and Hierarchy

        setPickerPlanSelected(state) {
            state.pickerPlanSelected = true;
        },

        /**
         *
         * @param state
         * @param payload
         */
        setPlans(state, payload) {
            state.plans = [];
            state.plans = payload;
        },

        /**
         *
         * @param state
         * @param payload
         */
        setPlanTier(state, planTier) {
            state.planTier = Domain.isDomestic() ? planTier : planTier.planTier;
        },

        /**
         *
         * @param state
         * @param payload
         */
        setPlanType(state, planType) {
            state.planType = Domain.isDomestic() ? planType : planType.planType;
        },

        /**
         * Do not call this method directly, use updateSelectedPlan instead
         */
        setSelectedPlan(state, selectedPlan) {
            if (Domain.isDomestic()) {
                state.plans.forEach((plan) => {
                    plan.isActive =
                        plan.tier === selectedPlan.tier &&
                        plan.planType === selectedPlan.planType &&
                        plan.trial === selectedPlan.trial;
                });
                state.planTier = selectedPlan.tier;
                state.selectedPlan = selectedPlan;
            } else {
                state.plans.forEach((obj, idx) => {
                    state.plans[idx].isActive = false;
                    if (obj.planType === state.planType) {
                        state.plans[idx].isActive = idx === selectedPlan.idx;
                    }
                });
                state.planTier = selectedPlan.tier;
            }
        },

        /**
         * Private, will clear the existing array of plans when XHR call returns.
         * The XHR call should only occur if the currentPlanType is null or there are no plans in the monthly or annual bucket
         * When user state changes currentPlanType should be reset to null (or need to come up with another flag)
         * @param state
         */
        __clearPlans(state) {
            state.plans = [];
        },

        /**
         * Private, called when XHR to get plans returns
         * @param state
         * @param payload
         */
        __appendPlan(state, payload) {
            payload.couponString = '';
            state.plans.push(payload);
        },

        setAddonShowtime(state, addonShowtime) {
            state.addonShowtime = Boolean(addonShowtime);
        },

        /**
         *
         * @param state
         * @param payload
         */
        updatePlanCouponString(state, payload) {
            state.plans[payload.idx].couponString = payload.couponString;
        },

        /**
         * @param state
         * @param payload
         */
        setSelectedPlanWithTypeandTier(state, payload) {
            const selectedPlan = state.plans.find((plan) => {
                return (
                    plan.tier === payload.planTier &&
                    plan.planType === payload.planType &&
                    plan.trial === payload.trial
                );
            });

            state.selectedPlan = selectedPlan;
        },

        /**
         * @param state
         * @param payload
         */
        setSelectedPlanWithTypeandPackage(state, payload) {
            const selectedPlan = state.plans.find((plan) => {
                return (
                    plan.package_code === payload.package_code && plan.planType === payload.planType
                );
            });

            state.selectedPlan = selectedPlan;
        },

        /**
         *
         * @param state
         * @param payload
         */
        setPlanCoupon(state, payload) {
            if (!payload.recurlyCode) {
                return;
            }

            const plan = state.plans.find((plan) => {
                return plan.recurlyCode === payload.recurlyCode;
            });

            if (plan) {
                plan.setCoupon(payload);
            }
        },
    },

    // updates the state
    // actions call mutations, which update state
    actions: {
        /**
         *
         * @param context
         * @param payload
         */
        setPlans(context, payload) {
            context.dispatch('__clearPlans');
            for (let i = 0; i < payload.length; i++) {
                payload[i].isActive = false;
                context.dispatch('__appendPlan', payload[i]);
            }
        },

        /**
         *
         * @param context
         * @param payload
         */
        setPlanType(context, payload) {
            context.commit('setPlanType', payload);
        },

        /**
         *
         * @param context
         * @param payload
         */
        setPlanTier(context, payload) {
            context.commit('setPlanTier', payload);
        },

        /**
         *
         * @param context
         * @private
         */
        __clearPlans(context) {
            context.commit('__clearPlans');
        },

        /**
         *
         * @param context
         * @param payload
         * @private
         */
        __appendPlan(context, payload) {
            if (payload) {
                context.commit('__appendPlan', payload);
            }
        },

        /**
         *
         * @param context
         * @param getters
         * @returns {Promise<any>}
         */
        getPlans({ context, getters }) {
            let self = this;

            return new Promise(function (resolve, reject) {
                let hasPlans = getters['hasPlans'];
                if (hasPlans) {
                    resolve(getters['getPlans']);
                } else {
                    PlanService.getPlans()
                        .then((res) => {
                            self.dispatch('plan/setPlans', res);
                            resolve(res);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                }
            });
        },

        /**
         *
         * @param context
         * @param payload
         */
        setSelectedPlan(context, payload) {
            context.commit('setSelectedPlan', payload);
            localStorage.setItem('selectedPlan', JSON.stringify(payload));
        },

        updatePlanCouponString(context, payload) {
            Domain.isDomestic()
                ? context.dispatch('usUpdatePlanCouponString', payload)
                : context.dispatch('intlUpdatePlanCouponString', payload);
        },

        /**
         * Update the trial copy when an URL coupon is being used
         * @param context
         * @param payload
         */
        intlUpdatePlanCouponString({ commit, getters }, payload) {
            if (payload) {
                let newPayload = payload;
                commit('updatePlanCouponString', newPayload);
            }
        },

        /**
         * Update the trial copy when an URL coupon is being used
         * @param commit
         * @param getters
         * @param recurlyCode
         * @param couponString
         * @param couponType
         */
        usUpdatePlanCouponString({ commit, getters }, { recurlyCode, couponString, couponType }) {
            if (recurlyCode) {
                commit('setPlanCouponString', {
                    recurlyCode,
                    couponString,
                    couponType,
                });
            }
        },

        /**
         *
         * @param context
         * @param payload
         */
        updatePlans({ commit }, payload) {
            let plans = [];
            if (Array.isArray(payload)) {
                plans = payload.map((obj) => {
                    return new Plan(obj);
                });
            }
            commit('setPlans', plans);
        },

        updateAddonShowtime({ commit, rootState }, addonShowtime) {
            commit('setAddonShowtime', addonShowtime);

            CheckoutOptions.get().save({
                addonShowtime: addonShowtime,
                userID: rootState.user?.regID,
            });
        },

        filterPlans({ commit }, payload) {
            if (payload.planType) {
                commit('setPlanType', payload.planType);
            }
        },

        /**
         *
         * @param context
         * @param getters
         * @returns {Promise<any>}
         */
        fetchPlans({ getters, dispatch }) {
            return new Promise(function (resolve, reject) {
                let hasPlans = getters['hasPlans'];
                if (hasPlans) {
                    resolve(getters['getPlans']);
                } else {
                    PlanService.getPlans()
                        .then((plans) => {
                            dispatch('updatePlans', plans);
                            resolve(plans);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                }
            });
        },

        /**
         *
         * @param context
         * @param plan
         */
        updateSelectedPlan(context, plan) {
            context.commit('setSelectedPlan', plan);
            context.commit('setAddonShowtime', context.getters.selectedPlan.bundleShowtime);
        },

        /**
         *
         * @param context
         * @param planType
         * @param planTier
         */
        updateSelectedPlanWithTypeandTier(context, payload) {
            context.commit('setSelectedPlanWithTypeandTier', payload);
        },

        /**
         *
         * @param context
         * @param planType
         * @param planTier
         */
        updateSelectedPlanWithTypeandPackage(context, payload) {
            context.commit('setSelectedPlanWithTypeandPackage', payload);
        },

        /**
         * Update the trial copy when an URL coupon is being used
         * @param commit
         * @param payload
         */
        updatePlanCoupon({ commit }, payload) {
            if (payload.recurlyCode) {
                commit('setPlanCoupon', payload);
            }
        },

        loadStore({ commit, dispatch }, payload) {
            if (payload && typeof payload === 'object') {
                commit('setPlanType', payload.planType);

                const savedOptions = CheckoutOptions.get();
                commit('setAddonShowtime', savedOptions.addonShowtime);

                dispatch('updatePlans', payload.plans || []);
            }
        },

        async updatePricingInfo({ dispatch }, { plan, coupon }) {
            if (coupon) {
                await dispatch('updatePricingInfoWithCoupon', { plan, coupon });
            }
        },

        async updatePricingInfoWithCoupon({ dispatch, rootState, rootGetters }, { plan, coupon }) {
            if (typeof plan.couponIsValid === 'boolean' || rootGetters['flow/isGift']) {
                return;
            }

            rootState.payment.recurly.configure({
                publicKey: rootState.payment.recurlyPublicKey,
                cors: true,
            });

            let pricing = rootState.payment.recurly.Pricing();
            let couponConfig, couponPrice;

            const couponPromise = new Promise((resolve, reject) => {
                pricing.on('set.coupon', (coupon) => {
                    // only change trial copy if the coupon type is not percent
                    dispatch('payment/processCoupon', coupon, { root: true })
                        .then(({ data }) => {
                            resolve({
                                recurlyCode: plan.recurlyCode,
                                couponString: data.coupon?.string.standard,
                                couponIsValid: true,
                                recurlyCoupon: coupon,
                                couponRemovesTrial: !data.trialEligible,
                                couponDiscount: coupon?.discount,
                                applies_for_months: coupon?.applies_for_months,
                            });
                        })
                        .catch((e) => {
                            // console.error(e)
                            resolve({});
                        });
                });

                pricing.on('error.coupon', (coupon) => {
                    resolve({
                        recurlyCode: plan.recurlyCode,
                        couponIsValid: false,
                    });
                });
            }).then((result) => {
                couponConfig = result;
            });

            const pricePromise = new Promise((resolve, reject) => {
                pricing
                    .plan(plan.recurlyCode)
                    .coupon(coupon)
                    .catch((e) => {
                        // console.error(e)
                    })
                    .done((price) => {
                        resolve({
                            recurlyPrice: price,
                            recurlyCouponSubtotal: new Intl.NumberFormat(
                                CBS.Registry.region.locale,
                                {
                                    style: 'currency',
                                    currency: price.currency.code,
                                },
                            ).format(price.next.subtotal),
                        });
                    });
            }).then((result) => {
                couponPrice = result;
            });

            await Promise.allSettled([couponPromise, pricePromise]);
            await dispatch('updatePlanCoupon', Object.assign(couponConfig, couponPrice));
        },
    },

    // access the state
    getters: {
        getPlans: (state, getters, obj = {}) => {
            return getters[Domain.isDomestic() ? 'usGetPlans' : 'intlGetPlans'];
        },

        /**
         * Will return an array of either monthly or annual plans, or empty array
         * @param state
         * @param getters
         * @param pageAttributes (rootState.pageAttributes)
         * @return {*}
         */
        intlGetPlans: (state, getters, { pageAttributes }) => {
            try {
                let plans = state.plans.filter((obj) => {
                    return obj.planType === state.planType;
                });

                return plans;
            } catch (e) {
                return [];
            }
        },

        /**
         * Will return an array of either monthly or annual plans, or empty array
         * @param state
         * @param getters
         * @return {*}
         */
        usGetPlans: (state, getters) => {
            if (state.planType === Product.TYPE_ANNUAL) {
                return getters.annualPlans;
            }

            return getters.monthlyPlans;
        },

        lcpAnnualPlan(state, getters, rootState, rootGetters) {
            try {
                const lcpAnnual = getters.annualPlans.find((plan) => {
                    return plan.isLCP && plan.bundleShowtime == rootGetters.bundleShowtime;
                });

                return lcpAnnual;
            } catch (e) {
                return null;
            }
        },

        cfAnnualPlan(state, getters, rootState, rootGetters) {
            try {
                const cfAnnual = getters.annualPlans.find((plan) => {
                    return plan.isCF && plan.bundleShowtime == rootGetters.bundleShowtime;
                });

                return cfAnnual;
            } catch (e) {
                return null;
            }
        },

        /**
         *
         * @param state
         * @returns {boolean}
         */
        hasPlans: (state) => {
            return state.plans.length > 0;
        },

        /**
         * @param state
         * @returns {T[]}
         */
        monthlyPlans: (state) => {
            return state.plans.filter((plan) => {
                return plan.planType === Product.TYPE_MONTHLY;
            });
        },

        getMonthlyPlans: function (state) {
            return this.monthlyPlans(state);
        },

        /**
         *
         * @param state
         * @returns {T[]}
         */
        annualPlans: (state) => {
            return state.plans.filter((plan) => {
                return plan.planType === Product.TYPE_ANNUAL;
            });
        },

        getAnnualPlans: function (state) {
            return this.annualPlans(state);
        },

        annualPlanFilterIsOn: (state) => {
            return state.planType === Product.TYPE_ANNUAL;
        },

        selectedPlan: (state) => {
            if (!state.selectedPlan?.recurlyCode) {
                return null;
            }

            return state.plans.find((plan) => {
                return plan.recurlyCode === state.selectedPlan.recurlyCode;
            });
        },

        getCurrentPlan(state, getters) {
            return getters[Domain.isDomestic() ? 'usGetCurrentPlan' : 'intlGetCurrentPlan'];
        },

        // START TEST PLOCTOPLUS-2947 Annual Plan Emphasis on PAP Adjusted Pricing Display and Hierarchy
        getBillingCadenceModalOpen(state) {
            return state.billingCadenceModalOpen;
        },
        getCurrentAnnualPlan(state, getters) {
            return getters.annualPlans.find((plan) => {
                return plan.tier == state.planTier;
            });
        },
        // END TEST PLOCTOPLUS-2947 Annual Plan Emphasis on PAP Adjusted Pricing Display and Hierarchy


        getEduOfferPlan(state, getters) {
            return getters.getPlans.find((plan) => {
                return (
                    plan.trial &&
                    plan.package_code == Product.PACKAGE_CODE_LCP &&
                    plan.planType === Product.TYPE_MONTHLY
                );
            });
        },

        /**
         * Returns an array of annual plans that have the same tier and trial as the current monthly plan.
         *
         * @param {Object} state - The Vuex state object.
         * @param {Object} getters - The Vuex getters object.
         * @returns {Array} - An array of annual plans.
         */
        monthlySelectedAndAnnualAvailable(state, getters) {
            const currentPlan = getters.getCurrentPlan;

            if (currentPlan.planType !== Product.TYPE_MONTHLY) {
                return [];
            }

            return getters.annualPlans.filter((annualPlan) => {
                return (
                    currentPlan.tier === annualPlan.tier && currentPlan.trial === annualPlan.trial
                );
            });
        },

        // START TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX
        annualSelectedAndMonthlyAvailable(state, getters, rootState, rootGetters) {
            return getters.monthlyPlans.filter((plan) => {
                return (
                    getters.getCurrentPlan.tier === plan.tier &&
                    getters.getCurrentPlan.trial === plan.trial
                );
            });
        },
        // END TEST PLOCTOPLUS-2572: A/B Test: Annual Upsell on Payment Page - Revised UI/UX

        getPromoOfferStringForTracking: (state) => {
            let promoOffer = '';
            state.plans.forEach((plan) => {
                if (plan.hasValidCoupon()) {
                    if (promoOffer !== '') {
                        promoOffer += ' | ';
                    }
                    promoOffer += plan.titleForTracking + ' - ' + plan.couponString;
                }
            });
            return promoOffer;
        },

        /**
         *
         * @param state
         * @param getters
         * @returns {*}
         */
        intlGetCurrentPlan: (state, getters) => {
            let plans = getters.getPlans;

            let activePlan = plans.filter((obj) => {
                return obj.isActive;
            });

            if (activePlan.length === 0 && plans.length > 0) {
                activePlan = plans[0];
            } else {
                activePlan = activePlan[0];
            }

            return activePlan;
        },

        /**
         *
         * @param state
         * @param getters
         * @returns {*}
         */
        usGetCurrentPlan: (state, getters, rootState, rootGetters) => {
            if (rootGetters['flow/isEdu']) {
                return getters.getEduOfferPlan;
            }

            return getters.selectedPlan || getters.getPlans[0];
        },

        /**
         *
         * @param state
         * @param getters
         * @param rootState
         * @param rootGetters
         * @returns {*}
         */
        getCurrentPlanTrialCopy: (state, getters, rootState, rootGetters) => {
            let currentPlan = getters.getCurrentPlan;
            let pageAttrs = rootGetters['pageAttributes/getAllPageAttributes'];
            let plan = new Plan(currentPlan, pageAttrs);
            return plan.getTrialCopy();
        },

        getPlanLabelKey: (state, getters, rootState, rootGetters) => {
            const annualPlanOption = rootGetters.getServerDataByKey('annualPlanOption');
            const planCases = {
                annual: '_annual',
                monthly: '_monthly',
            };
            const planTypeValue = !annualPlanOption ? Product.TYPE_MONTHLY : state.planType;
            const planTypeChunk = !annualPlanOption ? '' : (planCases[planTypeValue] ?? '');

            return `paramount_plus${planTypeChunk}`;
        },
    },
};
